@font-face {
  font-family: 'FuturaBook';
  src: url("./assets/fonts/FuturaBook.woff") format('woff'),
       url("./assets/fonts/FuturaBook.woff2") format('woff2'),
       url("./assets/fonts/FuturaBook.ttf") format('truetype');
}

@font-face {
  font-family: 'FuturaMedium';
  src: url("./assets/fonts/FuturaMedium.woff") format('woff'),
       url("./assets/fonts/FuturaMedium.woff2") format('woff2'),
       url("./assets/fonts/FuturaMedium.ttf") format('truetype');
}

@font-face {
  font-family: 'FuturaBold';
  src: url("./assets/fonts/FuturaBold.woff") format('woff'),
       url("./assets/fonts/FuturaBold.woff2") format('woff2'),
       url("./assets/fonts/FuturaBold.ttf") format('truetype');
}

body {
  background-color: #EEEFF1;
  font-family: "FuturaBook", Arial, Helvetica, sans-serif !important;
  color: #000 !important;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}

nav {
  margin-top: 10px;
  background-color: transparent !important;
  .navbar-brand {
    font-family: "FuturaBold";
    font-size: 24px;
    color: #000;
  }
  .nav-item {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    .nav-link {
      font-family: "FuturaMedium";
      font-size: 18px;
      color: #000;
    }
    span {
      display: block;
      padding-top: 8px
    }
  }
  .nav-link {
    font-family: "FuturaMedium";
    font-size: 18px;
    color: #000 !important;
  }
  button.btn.portal {
    border-radius: 50px;
    background-color: #000;
    color: #fff;
    font-family: "FuturaMedium";
    font-size: 18px;
    padding: 8px 20px !important;
  }
  button.navbar-toggler {
    border: none;
  }
}

section#one {
  min-height: 80vh;
  height: auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
  .special-img {
    width: 45%;
    height: auto;
    margin-top: 5rem;
  }
  .desktop-home-img {
    width: 100%;
    height: auto;
  }
  > .row {
    height: 100%;
    .d-flex {
      height: 100%;
      .pretitle {
        font-family: "FuturaMedium";
        font-size: 20px;
        color: #000;
      }
      .subtitle {
        font-family: "FuturaMedium";
        font-size: 18px;
        color: #000;
      }
      h1.title {
        font-family: "FuturaBold";
        font-size: 60px;
        color: #000;
      }
      .showmore a {
        font-family: "FuturaMedium";
        font-size: 18px;
        color: #000;
        text-decoration: underline;
      }
    }
  }
}

section#prices {
  h1 {
    font-family: "FuturaBold";
    font-size: 40px;
    color: #000;
  }
  .price-table {
    border-radius: 20px;
    min-height: 380px;
    padding: 2rem 3rem;
    h4 {
      font-size: 23px;
      text-align: center;
      font-family: "FuturaBold";
    }
    h2 {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 45px;
      text-align: center;
      font-family: "FuturaBold";
      span {
        font-size: 40px;
        font-family: "FuturaBold";
      }
    }
    p {
      font-size: 18px;
      font-family: "FuturaBold";
      color: rgba(255,255,255,0.8);
      margin-bottom: 0px;
    }
    &.one {
      background-color: rgba(120,186,36,0.5);
      h4, h2 {
        color: #788C5F;
      }
    }
    &.two {
      background-color: rgba(48,54,197,0.5);
      h4, h2 {
        color: #595C8B;
      }
    }
    &.three {
      background-color: rgba(208,64,151,0.5);
      h4, h2 {
        color: #8D657E;
      }
      h2 {
        font-size: 35px;
      }
    }
  }
}

section#about {
  margin-top: 230px;
  h1 {
    font-family: "FuturaBold";
    font-size: 40px;
    color: #000;
  }
  p {
    font-size: 18px;
    font-family: "FuturaMedium";
  }
  svg {
    width: 100%;
    height: auto;
  }
}

section#footer {
  margin-top: 230px;
  margin-bottom: 50px;
  h1 {
    font-family: "FuturaBold";
    font-size: 40px;
    color: #000;
  }
  p {
    font-size: 18px;
    font-family: "FuturaMedium";
    a {
      font-size: 18px;
      font-family: "FuturaMedium";
      color: #000;
      text-decoration: underline;
    }
  }
  .brand-footer {
    p {
      font-family: "FuturaBold";
      font-size: 24px;
      color: #000;
      text-align: right;
    }
  }
}

.mobile-flex-box {
  display: flex;
}

@media (max-width: 1200px) {
  nav {
    margin-top: 10px;
    .navbar-brand {
      font-size: 24px;
    }
    .nav-item {
      margin-left: 0.4rem;
      margin-right: 0.4rem;
      .nav-link {
        font-size: 18px;
      }
      span {
        padding-top: 8px;
        font-size: 25px !important;
      }
    }
    .nav-link {
      font-size: 25px !important;
    }
    button.btn.portal {
      border-radius: 50px;
      font-size: 18px;
      padding: 8px 20px !important;
    }
  }

  .navbar-collapse {
    position: fixed !important;
    background-color: #EEEFF1 !important;
    top: 8%;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1010;
    .navbar-nav {
      height: 80%;
      text-align: center;
      justify-content: center;
    }
    .mobile-flex-box {
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-link {
        font-size: 18px !important;
      }
    }
  }
  section#one {
    min-height: 85vh;
    height: auto;
    padding-top: 4rem;
    padding-bottom: 6rem;
    .special-img {
      display: none;
    }
    .desktop-home-img {
      width: 100%;
      height: auto;
      margin-bottom: 6rem
    }
    > .row {
      height: 100%;
      .d-flex {
        height: 100%;
        .pretitle {
          font-size: 20px;
        }
        .subtitle {
          font-size: 18px;
        }
        h1.title {
          font-size: 36px;
        }
        .showmore a {
          font-size: 18px;
        }
      }
    }
  }

  section#prices {
    h1 {
      font-size: 30px;
    }
    .price-table {
      height: auto;
      min-height: auto;
      margin-bottom: 1rem;
      padding: 2rem 3rem;
      h4 {
        font-size: 16px;
        text-align: center;
      }
      h2 {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 40px;
        text-align: center;
        span {
          font-size: 23px;
        }
      }
      p {
        font-size: 15px;
      }
      &.one {
        h4, h2 {
          color: #788C5F;
        }
      }
      &.two {
        h4, h2 {
          color: #595C8B;
        }
      }
      &.three {
        h2 {
          font-size: 23px;
        }
      }
    }
  }

  section#about {
    margin-top: 160px;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 15px;
      margin-bottom: 2rem;
    }
    .p-0 {
      max-width: 15rem !important;
    }
  }

  section#footer {
    margin-top: 180px;
    margin-bottom: 50px;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
    .brand-footer {
      p {
        font-size: 18px;
        text-align: left;
      }
    }
  }
}

@media (max-width: 600px) {
  section#about {
    .p-0 {
      max-width: 8rem !important;
    }
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1500px;
  }
}

@media (min-width: 1200px) {
  .contactMe {
    margin-top: 4px;
  }
}

.navbar-collapse.collapsing {
  -webkit-transition: height 0.01s;
  -moz-transition: height 0.01s;
  -ms-transition: height 0.01s;
  -o-transition: height 0.01s;
  transition: height 0.01s;
}
